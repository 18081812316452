.container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-inline-start: 0px;
}

.page {
  cursor: pointer;
  color: #d9d9d9;
  font-size: 12px;
  font-weight: 700;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page:hover {
  width: 28px;
  height: 28px;
  margin: 2px;
  border-radius: 3px;
  color: #122d58;
  background-color: #ececec;
}

.pageActive {
  cursor: pointer;
  color: #122d58;
  font-size: 12px;
  font-weight: 900;
  width: 32px;
  height: 32px;
  border-radius: 3px;
  background-color: #fff28d;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pageActive:hover {
  width: 32px;
  height: 32px;
  margin: 0px;
  border-radius: 3px;
  background-color: #fff28d;
}

.previous {
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #122d58;
}

.next {
  cursor: pointer;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #122d58;
}

.break {
  color: #d9d9d9;
  font-size: 12px;
  font-weight: 700;
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.link {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
}
